import * as React from "react"
import styled from 'styled-components';

//Components
import ProjectInfo from '../../components/ProjectInfo';
import ProjectHeader from '../../components/ProjectHeader';
import ProjectSubHeader from "../../components/ProjectSubHeader";
import FooterWithPadding from '../../components/FooterWithPadding';

//Videos
import MainActionButton from "../../images/candor/main-action-button.mp4"
import MoreButton from "../../images/candor/more-button.mp4"
import ShareCandor from "../../images/candor/share-candor-button.mp4"
import JoinCandor from "../../images/candor/joining-a-candor.mp4"
import RevealPhase from "../../images/candor/reveal-phase.mp4"
import ImageGallery from "../../images/candor/image-gallery.mp4"
import SnapScroll from "../../images/candor/snap-scroll.mp4"
import ToastSuccess from "../../images/candor/toast-success.mp4"
import ToastError from "../../images/candor/toast-error.mp4"

const CandorPage = () => {
  return (
    <Grid>
      <ProjectHeader 
        title="Candor"
        subTitle="A better way to brainstorm"
      />
      <ProjectInfo 
        when="Summer - Fall 2018"
        platform="Mobile Web"
        team="1 Lead Designer, 1PM, 1 Creative Technologist (me)"
        role="Motion/UI Design, Code Prototyping, User Testing"
        tools="Sketch, Figma, Principle, Haiku, React, Styled Components, Storybook JS, HTML, CSS"

        symbolPlatform="📱"
      />

      <div className="company-info">
        <span>Candor</span> is a Geniune bias-free Idea Generation for the Digital Age. Candor allows you to quickly collect and organize the independent ideas generated by your team, before discussing them in a group.
      </div>
      <div className="background">
        <p className="background-title">Background</p>
        <p className="background-text">
        I was hired as a contractor by 8bit Studios to help them work on their in-house startup, Candor.
        I was brought in on a hybrid exploratory role to help contribute to the UI, finalize user flows, 
        and establish motion designs. I also handled researching and validating the feasibility of 
        pulling it all off on the mobile web. This app faced a unique challenge as it was specifically 
        being built for the mobile web, not a hybrid or native app. I tested different web technologies 
        and built code and motion prototypes. I was essentially the creative/design technologist for this project.
        </p>
      </div>
      <div className="background">
        <p className="background-title">Project Highlights</p>
        <p className="background-text"><span className="star">⭐️</span><span>-</span>Worked with stakeholders, engineers, and designers to ensure interaction design and animations were feasible for the mobile web.</p>
        <p className="background-text"><span className="star">⭐️</span><span>-</span>Led the motion/interaction design for how the app would look and feel.</p>
        <p className="background-text"><span className="star">⭐️</span><span>-</span>Contributed to final UI design elements throughout the app, working with Lead UI designer. </p>
        <p className="background-text"><span className="star">⭐️</span><span>-</span>Created & handed off a design token/component library built with React for the devs to get off to a quicker start.</p>
        <p className="background-text"><span className="star">⭐️</span><span>-</span>Worked with Lead UX Designer doing internal user testing on the flows we were designing.</p>
      </div>


      <ProjectSubHeader 
        title="Motion Design Showcase"
        button="View Pattern Library"
        href="https://inspiring-morse-4ce9a3.netlify.app/"
      />

      <ProjectShowcaseCard>
        <div className="video-grid">
          <div className="badge-grid">
            <span>Home Action Button</span>
          </div>
          <video autoPlay muted loop>
            <source src={MainActionButton} type="video/mp4"/>
          </video>
        </div>
        <div className="video-grid">
          <div className="badge-grid">
            <span>More Button</span>
          </div>
          <video autoPlay muted loop>
            <source src={MoreButton} type="video/mp4"/>
          </video>
        </div>
        <div className="video-grid">
          <div className="badge-grid">
            <span>Share Candor</span>
          </div>
          <video autoPlay muted loop>
            <source src={ShareCandor} type="video/mp4"/>
          </video>
        </div>
      </ProjectShowcaseCard>

      <ProjectShowcaseCard>
        <div className="video-grid">
          <div className="badge-grid">
            <span>Joining a Candor</span>
          </div>
          <video autoPlay muted loop>
            <source src={JoinCandor} type="video/mp4"/>
          </video>
        </div>
        <div className="video-grid">
          <div className="badge-grid">
            <span>Top Idea Reveal</span>
          </div>
          <video autoPlay muted loop>
            <source src={RevealPhase} type="video/mp4"/>
          </video>
        </div>
        <div className="video-grid">
          <div className="badge-grid">
            <span>Image Gallery</span>
          </div>
          <video autoPlay muted loop>
            <source src={ImageGallery} type="video/mp4"/>
          </video>
        </div>

      </ProjectShowcaseCard>

      <ProjectShowcaseCard>
        <div className="video-grid">
          <div className="badge-grid">
            <span>Snap Scroll</span>
          </div>
          <video autoPlay muted loop>
            <source src={SnapScroll} type="video/mp4"/>
          </video>
        </div>
        <div className="video-grid">
          <div className="badge-grid">
            <span>Toast Success</span>
          </div>
          <video autoPlay muted loop>
            <source src={ToastSuccess} type="video/mp4"/>
          </video>
        </div>
        <div className="video-grid">
          <div className="badge-grid">
            <span>Toast Error</span>
          </div>
          <video autoPlay muted loop>
            <source src={ToastError} type="video/mp4"/>
          </video>
        </div>

      </ProjectShowcaseCard>

    <FooterWithPadding />
    </Grid>
  )
}

export default CandorPage




const Grid = styled.div`
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  max-width: 1110px;
  margin: 0 auto;

  .company-info {
    font-weight: 400;
    font-size: clamp( 16px,2vw,18px);
    line-height: 1.6;
    margin: 32px 0px;
    padding: 0px 0px 0px 11px;
    border-left: 3px solid var(--black-blue);
    color: var(--black-blue);
    span {
      font-weight: bold;
    }
    @media (max-width: 1200px) {
      padding-left: 48px;
      padding-right: 48px;
    }
    @media (max-width: 850px) {
      padding-left: 48px;
      padding-right: 48px;
    }
    @media (max-width: 650px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .background {
    .background-title {
      font-weight: 600;
      font-size: clamp( 16px,2vw,18px);
      padding: 0px 0px 16px 0px;
      color: #0c0c1d;
    }
    .background-text {
      font-weight: 400;
      font-size: clamp( 16px,2vw,18px);
      line-height: 1.6;
      color: var(--off-black);
			padding-bottom: 2.5%;
      span {
        font-weight: 900;
      }
    }
    p .star {
      padding-right: 1%;
    }
    p span {
      font-weight: 900;
      padding-right: .5%;
    }
    @media (max-width: 1200px) {
      padding-left: 48px;
      padding-right: 48px;
    }
    @media (max-width: 850px) {
      padding-left: 48px;
      padding-right: 48px;
    }
    @media (max-width: 650px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  @media (max-width: 650px) {
      p .star {
      display: none;
    }
  }
`

const ProjectShowcaseCard = styled.div`
  /* background: #F6F6F6;
  border-radius: 1rem; */
  padding: 48px;
  margin-top: 24px;
  border: .1rem solid #c7c7cc;
  box-shadow: 0 0.2rem 0.6rem 0 rgba(37,37,37,0.1);
  border-radius: 1rem;
  background: rgba(221, 221, 221, 0.11);

  display:grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: .1fr;
  grid-gap: 6%;

  .badge-grid {
    justify-content: center;
    display: flex;
    padding-bottom: 4%;
    span {
      background: var(--gray);
      color: #fff;
      padding: 1% 4%;
      border-radius: .5rem;
      font-weight: 500;
      font-size: clamp( 11px, 2vw, 16px);
      white-space: nowrap !important;
    }
  }

  @media (max-width: 900px) {
    padding: 24px;
  }

  video {
    width: 100% !important;
    height: auto !important;
    box-shadow: 0px 4px 12px 3px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    margin-right: 16px;
  }
`


